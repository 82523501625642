import React from "react"
import IconBox from "../IconBox/IconBox"
import * as classes from "./AboutUsSection.module.css"

import SearchIcon from "../../images/icons/search.svg"
import StarIcon from "../../images/icons/star.svg"
import PoundIcon from "../../images/icons/pound.svg"

const AboutUsNew = () => {
  const IconBoxData = [
    {
      title: "Whole Of Market",
      icon: SearchIcon,
      text:
        "We can source the entire range of mortgages in the market so you can be confident that we will find you the best mortgage deal available",
    },
    {
      title: "Quality Focused",
      icon: StarIcon,
      text:
        "We are focused on providing the best quality of service to our customers. Trust, integrity and value are at the heart of everything we do. ",
    },
    {
      title: "Our Fee",
      icon: PoundIcon,
      text:
        "We only charge a fee of £480 once we have successfully obtained you a mortgage offer. You do not pay us anything beforehand ",
    },
  ]

  return (
    <div className="grid container">
      <div className="centred section">
        <h2 style={{ color: "#054850" }}>About Us</h2>

        <div className={classes.AboutUsSection}>
          {IconBoxData.map(item => (
            <IconBox
              key={item.title}
              title={item.title}
              iconSrc={item.icon}
              text={item.text}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default AboutUsNew
