import React from "react"
import * as classes from "./AwardCard.module.css"

const AwardCard = props => {
  return (
    <div className={classes.AwardCard}>
      <div className={classes.ImgContainer}>
        <img width="200px" src={props.img} alt={props.alt || ""} />
      </div>
      {/* <h4 style={{ textAlign: "center" }}>{props.text}</h4> */}
    </div>
  )
}

export default AwardCard
