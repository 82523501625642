import React, { useState } from "react"
import { useForm } from "react-hook-form"
import * as classes from "./GetInTouchForm.module.css"
import Spinner from "../../Spinner/Spinner"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const GetInTouchForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [loading, setLoading] = useState(false)
  const [submissionComplete, setSubmissionComplete] = useState(false)

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const onSubmit = async data => {
    setLoading(true)
    try {
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "contact",
          ...data,
        }),
      })

      setLoading(false)
      setSubmissionComplete(true)
      typeof window !== "undefined" &&
        window.gtag("event", "conversion", {
          send_to: "AW-855424402/o9E4CIj746sBEJL78pcD",
        })
      typeof window !== "undefined" &&
        window.gtag("event", "conversion", { send_to: "UA-87814267-2" })

      // trackCustomEvent({
      //   // string - required - The object that was interacted with (e.g.video)
      //   category: "Form",
      //   // string - required - Type of interaction (e.g. 'play')
      //   action: "Form Submission",
      //   // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      //   label: "Form Submission",
      //   // value: number - optional - Numeric value associated with the event. (e.g. A product ID)
      // })

      //   reset()
    } catch (err) {
      console.log("error")
    }
  }

  if (loading === true) {
    return <Spinner />
  }
  if (submissionComplete === true) {
    return <span>Success!</span>
  }
  return (
    <form
      name="contact"
      data-netlify="true"
      id="reactContactForm"
      onSubmit={handleSubmit(onSubmit)}
      className={classes.Form}
    >
      {/* register your input into the hook by invoking the "register" function */}
      <input type="hidden" name="form-name" value="contact" />
      {/* include validation with required or other standard HTML validation rules */}
      <div className="getInTouch_Item">
        <div className="getInTouch_Item_content">
          <input
            {...register("firstName", { required: true })}
            type="text"
            placeholder="First Name"
          />
          {errors.firstName && (
            <span className="form_error">This field is required</span>
          )}
        </div>
      </div>
      <div className="getInTouch_Item">
        <div className="getInTouch_Item_content">
          <input
            {...register("lastName", { required: true })}
            type="text"
            placeholder="Last Name"
          />
          {errors.lastName && (
            <span className="form_error">This field is required</span>
          )}
        </div>
      </div>
      <div className="getInTouch_Item">
        <div className="getInTouch_Item_content">
          <input
            {...register("email", { required: true })}
            type="email"
            placeholder="Email"
          />
          {errors.email && (
            <span className="form_error">This field is required</span>
          )}
        </div>
      </div>
      <div className="getInTouch_Item">
        <div className="getInTouch_Item_content">
          <input
            {...register("phone", { required: true })}
            type="text"
            placeholder="Phone"
          />
          {errors.phone && (
            <span className="form_error">This field is required</span>
          )}
        </div>
      </div>

      <div
        className="getInTouch_Item getInTouch_TextArea"
        style={{ gridArea: "message" }}
      >
        <div className="getInTouch_Item_content">
          <textarea
            {...register("message", { required: true })}
            name="message"
            type="text"
            placeholder="Message"
            defaultValue={""}
          />
        </div>
      </div>

      {/* errors will return when field validation fails  */}

      {/* <input type="submit" /> */}
      <div className="getInTouch_Item">
        <div className="getInTouch_Item_content">
          <button type="submit" className="service--calculator__submit">
            Submit
          </button>
        </div>
      </div>
    </form>
  )
}

export default GetInTouchForm
