import React from "react"
import * as classes from "./IconBox.module.css"

const IconBox = props => {
  return (
    <div className={classes.IconBox}>
      <div className={classes.TitleSection}>
        <div className={classes.IconContainer}>
          <img
            src={props.iconSrc}
            alt={props.alt}
            style={{
              height: "50px",
              display: "block",
              margin: "auto",
              ...props.imgStyle,
            }}
          />
        </div>
        <h4 style={props.titleStyle} className={classes.Title}>
          {props.title}
        </h4>
      </div>
      <p style={props.textStyle} className={classes.Text}>
        {props.text}
      </p>
    </div>
  )
}

export default IconBox
