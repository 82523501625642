import React from "react"
import Img from "gatsby-image"
import * as classes from "./StaffCard.module.css"

const StaffCard = props => {
  return (
    <div className={classes.StaffCard} id={props.id}>
      <Img fixed={props.fixed} style={props.style} imgStyle={props.imgStyle} />
      <p className={classes.Title}>{props.name}</p>
      <p className={classes.JobTitle}>{props.jobTitle}</p>
    </div>
  )
}

export default StaffCard
