import React from "react"
import Logo from "../../images/footer-logo.png"

const Footer = () => {
  return (
    <footer className="grid main_footer">
      <div className="centred">
        <div className="grid container">
          <div className="grid footer_nav">
            <nav>
              <li>
                <a href="https://bennisonbrownmortgages.co.uk/about-us">
                  About Us
                </a>
              </li>
              <li>
                <a href="https://bennisonbrownmortgages.co.uk/services">
                  Services
                </a>
              </li>
              <li>
                <a href="https://bennisonbrownmortgages.co.uk/contact">
                  Contact
                </a>
              </li>
              <li>
                <a href="https://bennisonbrownmortgages.co.uk/faq">FAQ</a>
              </li>
              <li>
                <a href="https://bennisonbrownmortgages.co.uk/news">News</a>
              </li>
              <li>
                <a href="https://bennisonbrownmortgages.co.uk/mortgage-finder">
                  Mortgage Finder
                </a>
              </li>
              <li>
                <a href="https://bennisonbrownmortgages.co.uk/careers">
                  Careers
                </a>
              </li>
            </nav>
          </div>
          <div className="grid contact--footer">
            <p>BENNISON BROWN</p>
            <p>149c Manchester Road</p>
            <p>London</p>
            <p>E14 3DN</p>
          </div>
          <div className="grid footer_copy--container">
            <p>
              There maybe a fee for arranging a mortgage and the precise amount
              will depend on your circumstances. This will typically be £480.
              This fee is only payable when you receive the mortgage offer from
              the lender.
            </p>
            <p>
              <br />
              Your home may be repossessed if you do not keep up repayments on
              your mortgage. As with all insurance policies, conditions and
              exclusions will apply
            </p>
            <p>
              Bennison Brown Limited is an Appointed Representative of
              Stonebridge Mortgage Solutions Ltd, which is authorised and
              regulated by the Financial Conduct Authority. Registered Office:
              149c Manchester Road, London, E14 3DN. Registered Company Number:
              10300596 Registered in England &amp; Wales
              <br />
            </p>
          </div>
          <div className="grid main_logo--container__footer">
            <img src={Logo} alt="main Logo" />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
