import React from "react"
import StepCardNew from "./StepCard/StepCardNew"
import * as classes from "./StepByStep.module.css"

import Step1 from "../../images/stepIcons/step1.svg"
import Step2 from "../../images/stepIcons/step2.svg"
import Step3 from "../../images/stepIcons/step3.svg"
import Step4 from "../../images/stepIcons/step4.svg"
import Step5 from "../../images/stepIcons/step5.svg"
import Step6 from "../../images/stepIcons/step6.svg"

import Number1 from "../../images/numberIcons/1.png"
import Number2 from "../../images/numberIcons/2.png"
import Number3 from "../../images/numberIcons/3.png"
import Number4 from "../../images/numberIcons/4.png"
import Number5 from "../../images/numberIcons/5.png"
import Number6 from "../../images/numberIcons/6.png"

const StepByStepSection = props => {
  const info = [
    {
      number: Number1,
      icon: Step1,
      text: "We find out about your current circumstances",
      litNumber: 1,
      description:
        "One of our advisers will gather enough information on your current situation that will allow them to find the truly best mortgage options for you, specific to your circumstances. This conversation will usually take around 30 minutes.",
    },
    {
      number: Number2,
      icon: Step2,
      text: "We search the market for the best mortgage",
      litNumber: 2,
      description:
        "With access to every single lender that is available on the market, we look for mortgage deals that suit your requirements, and we contact lenders to make sure they will accept your application, should you use them.",
    },
    {
      number: Number3,
      icon: Step3,
      text: "We compare and present to you the best options available",
      litNumber: 3,
      description:
        "These aren't necessarily the absolutely lowest rates, as they incorporate all the criteria of what matters to you",
    },
    {
      number: Number4,
      icon: Step4,
      text: "We prepare and submit your application",
      litNumber: 4,
      description:
        "After you have chosen your preferred mortgage option, we will ensure we have everything we need to submit an application that the lender will accept.",
    },
    {
      number: Number5,
      icon: Step5,
      text: "We handle your application with the lender",
      litNumber: 5,
      description:
        "This involves making sure the application is staying on schedule, ensuring the valuation gets booked in and keeping you updated with any news that concerns you.",
    },
    {
      number: Number6,
      icon: Step6,
      text:
        "You receive your offer! Our £480 fee is payable, however we stick with you until completion.",
      litNumber: 6,
      description:
        "Your case manager and mortgage adviser will work together to ensure that all related parties (solicitors, estate agents etc.) do their job in timely fashion. Our goal is not just to get you your mortgage offer, it's to make sure you get your dream home!",
    },
  ]

  return (
    <div className="grid container" style={{ background: "#237988" }}>
      <div className="centred section">
        <h2 style={{ color: "white" }}>Step by step process</h2>
        {/* <div className={classes.Container}> */}
        {/* <div className={classes.TimelineContainer}>
            <div className={classes.Timeline} />
            {info.map(step => (
                <div className={classes.Number}>
                  <img
                    className={classes.Number}
                    src={step.number}
                    alt={step.litNumber}
                  />
                </div>
              ))}
            </div>
          </div> */}

        <div className={classes.StepsContainer}>
          {info.map(step => (
            <StepCardNew
              number={step.number}
              icon={step.icon}
              text={step.text}
              alt={step.litNumber}
              key={step.litNumber}
              description={step.description}
            />
          ))}
        </div>
        {/* </div> */}
      </div>
    </div>
  )
}

export default StepByStepSection
