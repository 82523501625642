import React from "react"
import * as classes from "./WhatMakesUsGreat.module.css"

import IconBox from "../IconBox/IconBox"
import FiveStars from "../../images/icons/five-stars.svg"
import Team from "../../images/icons/group.svg"
import Calendar from "../../images/icons/calendar.svg"

const WhatMakesUsGreat = () => {
  const IconBoxData = [
    {
      title: "Reviews",
      icon: FiveStars,
      text:
        "We are rated five-star on Google, with over 300 reviews. But it's not just the number, it's about what our customers are saying about us",
    },
    {
      title: "Dedicated Team",
      icon: Team,
      text:
        "We provide you with both a dedicated mortgage adviser and case manager, who support you throughout your entire mortgage journey",
    },
    // {
    //   title: "Quality Of Service",
    //   icon: QualityShield,
    //   text:
    //     "We only employ mortgage advisers that meet our rigorous standard of service",
    // },
    {
      title: "Flexible",
      icon: Calendar,
      text:
        "We are flexible. We also have appointments available on evenings and weekends",
    },
  ]
  return (
    // <div className={classes.WhatMakesUsGreatContainer}>
    <div className="grid container" style={{ background: "#237988" }}>
      <div className="centred section">
        <h2 style={{ color: "#FFF" }}>
          Why we are one of the highest rated mortgage brokers in the UK
        </h2>
        <div className={classes.WhatMakesUsGreat}>
          {IconBoxData.map(item => (
            <IconBox
              key={item.title}
              title={item.title}
              iconSrc={item.icon}
              text={item.text}
              textStyle={{ color: "white" }}
              titleStyle={{ color: "white" }}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default WhatMakesUsGreat
