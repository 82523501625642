import React, { useRef } from "react"
import * as classes from "./StepCard.module.css"
// import { ReactComponent as Dropdown } from "../../../images/icons/dropdown.svg"
import Dropdown from "../../Icons/Dropdown"

const StepCard = props => {
  // const [descriptionVisible, setDescriptionVisible] = useState(false)

  const toggleDropdown = () => {
    // setDescriptionVisible(!descriptionVisible)
    descriptionRef.current.classList.toggle(classes.VisibleDescription)
    if (dropdownArrow.current.style.transform !== "rotate(180deg)") {
      dropdownArrow.current.style.transform = "rotate(180deg)"
    } else {
      dropdownArrow.current.style.transform = "rotate(0deg)"
    }
  }

  const descriptionRef = useRef()
  const dropdownArrow = useRef()

  return (
    <div className={classes.CardContainer}>
      <div className={classes.Card}>
        {/* <div className={classes.Number}>
          <img
            className={classes.Number}
            src={props.number}
            alt={props.litNumber}
          /> */}
        {/* </div> */}
        <div className={classes.Icon}>
          <img className={classes.Icon} src={props.icon} alt={props.alt} />
        </div>

        <div className={classes.Text}>{props.text}</div>
        <div
          role="button"
          className={classes.Dropdown}
          onClick={toggleDropdown}
          onKeyDown={toggleDropdown}
          tabIndex={props.litNumber}
          ref={dropdownArrow}
        >
          <Dropdown height="25px" fill="#237988" />
        </div>
      </div>
      <div className={classes.Description} ref={descriptionRef}>
        <p>
          {props.description ||
            `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.`}
        </p>
      </div>
    </div>
  )
}

export default StepCard
