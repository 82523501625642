export default [
  {
    name: "Alex Esposito",
    title: "Mortgage Adviser",
    description: "",
    photoName: "alex-esposito",
    id: "alex-esposito",
    info: "",
  },
  {
    name: "Allan Lundy",
    title: "Mortgage Adviser",
    description: "",
    photoName: "allan-lundy",
    id: "allan-lundy",
    info: "Test info text for no reviews",
  },
  {
    name: "Amanda Thatcher",
    title: "Mortgage Adviser",
    description: "",
    photoName: "amanda-thatcher",
    id: "amanda-thatcher",
    info: "",
  },
  {
    name: "Andrew Brown",
    title: "Partner",
    description: "",
    photoName: "andrew-brown",
    id: "andrew-brown",
    info: "",
  },
  {
    name: "Andrew Connor",
    title: "Mortgage Adviser",
    description: "",
    photoName: "andrew-connor",
    id: "andrew-connor",
    info: "",
  },
  {
    name: "Bobbie-Jo Pickess",
    title: "Case Manager",
    description: "",
    photoName: "bobbie-jo-pickess",
    id: "bobbie-jo-pickess",
    info: "",
  },
  {
    name: "Chris Billingham",
    title: "Mortgage Adviser",
    description: "",
    photoName: "chris-billingham",
    id: "chris-billingham",
    info: "",
  },
  {
    name: "Clare Mason",
    title: "Customer Relationship Manager",
    description: "",
    photoName: "clare-mason",
    id: "clare-mason",
    info: "",
  },
  {
    name: "Daniel Gallagher-Soyinka",
    title: "Digital Marketer",
    description: "",
    photoName: "daniel-gallagher-soyinka",
    id: "daniel-gallagher-soyinka",
    info: "",
  },
  {
    name: "Grace Miles",
    title: "Bristol Office Manager",
    description: "",
    photoName: "grace-miles",
    id: "grace-miles",
    info: "",
  },
  {
    name: "Keith Timbrell",
    title: "Mortgage Adviser",
    description: "",
    photoName: "keith-timbrell",
    id: "keith-timbrell",
    info: "",
  },
  {
    name: "Laura Bryan",
    title: "Paraplanner",
    description: "",
    photoName: "laura-bryan",
    id: "laura-bryan",
    info: "",
  },
  {
    name: "Milan Tailor",
    title: "Protection Specialist",
    description: "",
    photoName: "milan-tailor",
    id: "milan-tailor",
    info: "",
  },
  {
    name: "Michael Bennison",
    title: "Partner",
    description: "",
    photoName: "michael-bennison",
    id: "michael-bennison",
    info: "",
  },
  {
    name: "Nicola Choudhury",
    title: "Paraplanner",
    description: "",
    photoName: "nicola-choudhury",
    id: "nicola-choudhury",
    info: "",
  },
  {
    name: "Spencer Komodromou",
    title: "Graphic Designer",
    description: "",
    photoName: "spencer-komodromou",
    id: "spencer-komodromou",
    info: "",
  },
  {
    name: "Susan Jarrett",
    title: "Case Manager",
    description: "",
    photoName: "susan-jarrett",
    id: "susan-jarrett",
    info: "",
  },
  {
    name: "Thomas Lombardo",
    title: "Mortgage Adviser",
    description: "",
    photoName: "thomas-lombardo",
    id: "thomas-lombardo",
    info: "",
  },
]
