import React, { useState, useRef, useEffect } from "react"
import staffInfo from "./StaffInfo"
import * as classes from "./Reviews.module.css"
import "../Slider/flickity.min.css"
import { useStaticQuery, graphql } from "gatsby"
import ReviewCard from "./ReviewsCarousel/ReviewCard/ReviewCard"
import StaffCard from "./StaffCarousel/StaffCard/StaffCard"
import Flickity from "react-flickity-component"
import PlaceholderCard from "./ReviewsCarousel/PlaceholderCard/PlaceholderCard"

const Reviews = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "staff" } }) {
        nodes {
          childImageSharp {
            fixed(width: 200, height: 200) {
              ...GatsbyImageSharpFixed
            }
          }
          name
        }
      }
      allReviewsCsv {
        nodes {
          id
          consultant
          case_manager
          author
          text
          date
        }
      }
    }
  `)

  let staffPhotos = data.allFile.nodes
  const allReviews = data.allReviewsCsv.nodes.filter(
    review => review.text.length > 25
  )

  // const consultantList = allReviews.map(review => review.consultant)
  // const caseManagerList = allReviews.map(review => review.case_manager)

  // let allStaffMembers = [...new Set(consultantList)].concat([
  //   ...new Set(caseManagerList),
  // ])

  // const staffList = [
  //   "Michael Bennison",
  //   "Andrew Connor",
  //   "Alex Esposito",
  //   "Milan Tailor",
  //   "Thomas Lombardo",
  //   "Andrew Brown",
  //   "Keith Timbrell",
  //   "Chris Billingham",
  //   "Laura Bryan",
  //   "Susan Jarrett",
  //   "Amanda Thatcher",
  //   "Clare Mason",
  //   "Grace Miles",
  //   "Bobbie-Jo Pickess",
  // ]
  // const staffList = [
  //   "Michael Bennison",
  //   "Andrew Connor",
  //   "Alex Esposito",
  //   "Milan Tailor",
  //   "Thomas Lombardo",
  //   "Andrew Brown",
  //   "Keith Timbrell",
  //   "Chris Billingham",
  //   "Laura Bryan",
  //   "Susan Jarrett",
  //   "Amanda Thatcher",
  //   "Clare Mason",
  //   "Grace Miles",
  //   "Bobbie-Jo Pickess",
  // ]

  // const staffInfo = staffInfoMain.filter(member =>
  //   staffList.includes(member.name)
  // )

  const [currentStaffMember, setCurrentStaffMember] = useState(staffInfo[0])

  useEffect(() => changeReviewsTo(currentStaffMember), [])
  const [reviews, setReviews] = useState(allReviews.slice(-5, -1))

  const switchStaffMemberTo = staffId => {
    if (staffRef.current.flkty.selectedCell.element.id !== currentStaffMember) {
      const newStaffMember = staffInfo.filter(
        member => member.photoName === staffId
      )[0]

      if (typeof newStaffMember !== "undefined") {
        setCurrentStaffMember(newStaffMember)

        changeReviewsTo(newStaffMember)
      }
    }
  }
  // const selectById = staffId => {
  //   console.log(
  //     staffId
  //     // staffRef.current.flkty.cells.filter(cell => cell.element.id === staffId)
  //   )
  // }

  const changeReviewsTo = staffMember => {
    const reviewsForStaffMember = allReviews.filter(
      review =>
        review.consultant === staffMember.name ||
        review.case_manager === staffMember.name
    )
    setReviews(reviewsForStaffMember)
  }

  const [staffMember, setStaffMember] = useState(staffInfo[0])

  const flickityOptions = {
    wrapAround: true,
    pageDots: false,
    draggable: false,
  }

  const staffRef = useRef()
  const reviewsRef = useRef()

  return (
    <div className="grid container">
      <div className={classes.ReviewSection}>
        <div className="centred section">
          <h2 style={{ color: "white" }}>Our Staff Go Above And Beyond</h2>
          <p style={{ color: "white" }}>
            We ensure every client receives expert customer service from the
            very first contact. During your time at Bennison Brown, your
            dedicated mortgage adviser and case manager work together to deliver
            an exceptional service, making your journey as stress-free as
            possible. Don't just take our word for it, you can browse our
            reviews below.
          </p>
          {/* <StaffCarousel
            currentStaffMember={staffMember}
            onClick={() => console.log(currentStaffMember.id)}
          /> */}
          <div
            className={classes.StaffCarousel}
            onClick={() =>
              switchStaffMemberTo(
                staffRef.current.flkty.selectedCell.element.id
              )
            }
          >
            <Flickity ref={staffRef} options={flickityOptions}>
              {staffPhotos.map(photo => (
                <StaffCard
                  key={photo.name}
                  name={currentStaffMember.name}
                  id={photo.name}
                  fixed={photo.childImageSharp.fixed}
                  style={{
                    border: "10px solid #054752",
                    display: "block",
                    alignSelf: "center",
                  }}
                  jobTitle={currentStaffMember.title}
                />
              ))}
            </Flickity>
          </div>
          {/* <ReviewsCarousel
            currentStaffMember={staffMember}
            onClick={() => switchStaffMemberTo(currentStaffMember.id)}
          /> */}

          <div className={classes.ReviewsCarousel}>
            <p
              style={{
                textAlign: "center",
                color: "#FFF",
                fontSize: "18px",
                fontWeight: "500",
                margin: "0 auto",
              }}
            >
              {`Read ${currentStaffMember.name.split(" ")[0]}'s reviews`}{" "}
            </p>
            <Flickity ref={reviewsRef} options={flickityOptions}>
              {reviews ? (
                reviews.map(review => (
                  <ReviewCard
                    key={review.id}
                    consultant={review.consultant}
                    text={review.text}
                    author={review.author.split(" ")[0]}
                    date={review.date.replace("01 ", "")}
                  />
                ))
              ) : (
                <PlaceholderCard info={staffMember.info} />
              )}
            </Flickity>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reviews
