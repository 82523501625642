import React from "react"
import BackgroundImage from "gatsby-background-image"
import { useStaticQuery, graphql, Link } from "gatsby"

const HeroSection = () => {
  const data = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: { eq: "home.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage
      Tag="section"
      className="grid main_header--services"
      fluid={data.bgImage.childImageSharp.fluid}
    >
      <div className="centred">
        <div className="grid container">
          <div className="grid header--content">
            <h1>Speak To A Quality Focused London Mortgage Broker</h1>
            <p />
            <div className="grid header--buttons">
              <Link to="/#contactSection" className="contact_popup_button">
                GET IN TOUCH
              </Link>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default HeroSection
