import React from "react"
import SEO from "../components/seo"
import StepByStepSectionNew from "../components/StepByStepSection/StepByStepSectionNew"
import WhatMakesUsGreat from "../components/WhatMakesUsGreat/WhatMakesUsGreat"
import Header from "../components/Header/Header"
import HeroSection from "../components/HeroSection/HeroSection"
import Footer from "../components/Footer/Footer"
import GetInTouch from "../components/GetInTouch/GetInTouch"
import Reviews from "../components/Reviews/Reviews"
import { graphql } from "gatsby"
import AboutUsNew from "../components/AboutUsNew/AboutUsNew"
import Awards from "../components/Awards/Awards"

const IndexPage = ({ data }) => {
  return (
    <div>
      {/* <!-- Global site tag (gtag.js) - Google Ads: 855424402 --> */}

      <SEO title="London Mortgage Broker" />
      <Header />
      <HeroSection />
      <AboutUsNew />
      <WhatMakesUsGreat />
      <Reviews />
      <Awards />
      <StepByStepSectionNew />
      <GetInTouch />
      <Footer />
    </div>
  )
}

export default IndexPage

export const query = graphql`
  query main {
    allImages: allImageSharp {
      edges {
        node {
          id
          parent {
            id
            ... on File {
              id
              name
              relativePath
            }
          }
          fluid(maxWidth: 200, maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
