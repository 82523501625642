import React from "react"
import * as classes from "./PlaceholderCard.module.css"

const PlaceholderCard = props => {
  return (
    <div className={classes.PlaceholderCard}>
      <div className={classes.Author}>{props.author}</div>
      <div className={classes.Date}>{props.date}</div>
      <div className={classes.Text}>
        <p>{props.text}</p>
      </div>
    </div>
  )
}

export default PlaceholderCard
