import React from "react"
import * as classes from "./Awards.module.css"
import AwardCard from "./AwardCard/AwardCard"
import ThreeBestRated from "../../images/socialProof/three-best-rated.svg"
import MoneyAgeLogo from "../../images/socialProof/money-age-logo-rsz.png"
import ftAdviserLogo from "../../images/socialProof/ft-adviser-rsz.png"
import ftbMagLogo from "../../images/socialProof/ftb-mag.png"

const Awards = () => {
  return (
    <div className="grid container">
      <div className="centred section">
        {/* <h2>As Seen In</h2> */}
        <div className={classes.Awards}>
          <AwardCard
            img={ThreeBestRated}
            text="Top 3 Best Rated Brokers 2021"
          />
          <AwardCard
            img={MoneyAgeLogo}
            text="Shortlisted for MoneyAge Small Broker of the year 2020"
          />
          <AwardCard
            img={ftAdviserLogo}
            text="Featured in Financial Times Adviser"
          />
          <AwardCard
            img={ftbMagLogo}
            text="Featured in Financial Times Adviser"
          />
        </div>
      </div>
    </div>
  )
}

export default Awards
