import React from "react"
import { Link } from "gatsby"
import Logo from "../../images/main-logo.png"

const Header = () => {
  const phoneNumber = "0203 034 0705"
  const email = "info@bennisonbrownmortgages.co.uk"
  return (
    <section className="grid main_logo--section">
      <div className="centred">
        <div className="grid container">
          <div className="grid main_logo--section__flex">
            <div className="grid main_logo--container">
              <a href="/">
                <img src={Logo} alt="main Logo" />
              </a>
            </div>
            <div className="grid phone_email--container__header">
              <p>
                <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
              </p>
              <p>
                <a href={`mailto:${email}`}>{email}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Header
