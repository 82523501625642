import React from "react"

import GetInTouchForm from "./GetInTouchForm/GetInTouchForm"
import * as classes from "./GetInTouch.module.css"
const GetInTouch = () => {
  return (
    <div className="grid container" id="contactSection">
      <div className="centred section">
        <div className={classes.Container}>
          <div className={classes.Info}>
            <h2 style={{ color: "#054850" }}>Organise a free consultation</h2>
            <p />
            <p>
              Leave your details or phone us now on
              <strong> 0203 034 0705</strong>
            </p>
            <p />
            <p className="contactForm_footer">
              We look forward to speaking to you soon. We don't use your details
              for marketing, only to get in touch and speak to you about your
              requirements.
            </p>
          </div>
          <GetInTouchForm />
        </div>
      </div>
    </div>
  )
}

export default GetInTouch
